<template>
	<div>
		<ContentHeader title="Membership" url="/data-membership" subTitle="Data Membership" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
								<h3 class="card-title col-6">Table Membership</h3>
								<div class="col-6 text-right">
									<router-link to="/tambah-data-membership"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
								</div>
							</div>
							<div class="card-body">
								<table class="table table-bordered table-hover">
									<thead>
										<tr>
										<th scope="col">NO</th>
										<th scope="col">NAMA</th>
										<th scope="col">ANGKATAN</th>
										<th scope="col">NPM</th>
										<th scope="col">ALAMAT</th>
										<th scope="col">NOMOR HP</th>
										<th scope="col">EMAIL</th>
										<th scope="col">FOTO</th>
										<th scope="col">ALAMAT PRAKTEK</th>
										<th scope="col">ACTION</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in data_result" :key="index">
										<td scope="row">{{ index + 1 }}</td>
										<td>{{ row.nama }}</td>
										<td>{{ row.angkatan }}</td>
										<td>{{ row.npm }}</td>
										<td>{{ row.alamat }}</td>
										<td>{{ row.no_hp }}</td>
										<td>{{ row.email }}</td>
										<td>{{ row.foto }}</td>
										<td>{{ row.alamat_praktek }}</td>
										<td class="text-sm text-center" style="width: 100px;">
											<i class="fas fa-download fa-lg ml-1" style="color: #039dc4;cursor: pointer;" @click="handleDownload(row.dokumen)"></i>
										</td>
										</tr>
									</tbody>
								</table>
								<p class="text-center mt-4" v-if="memuat_data">Memuat Data</p>
								<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
							</div>
							<div class="card-footer">
								<nav aria-label="...">
								<ul class="pagination">
									<li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
									<a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
									</li>
									<li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
									<li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
									<a class="page-link" href="#">Next</a>
									</li>
								</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import axios from 'axios'
// import store from '@/store'
import { ref, onMounted } from 'vue' 
import Swal from 'sweetalert2'

const data_result = ref([])
const memuat_data = ref(true)
const page = ref(10)
const links = ref([])
const pagination = ref([])
const kata_kunci = ref('')
const cek_data = ref(false)

const getDataResult = async () => {
	data_result.value = []
	memuat_data.value = true
	cek_data.value = false

	let parameter = ''
	if (kata_kunci.value != '') {
		parameter = kata_kunci.value
	} else {
		parameter = 'emptyParameter'
	}

	let { data } = await axios.get(`api/membership/${page.value}/${parameter}`)

	if (data != 'kosong') {
		memuat_data.value = false
		cek_data.value = false
		data_result.value = data.data
		links.value = data.links
		links.value.splice(0, 1)
		links.value.splice(links.value.length-1, 1)
		pagination.value = data
	} else {
		cek_data.value = true
		memuat_data.value = false
		data_result.value = []
	}
}

// const hapusData = (id) => {
// 	axios.delete(`api/membership/destroy/${id}`)
// 	.then((response) => {
// 		Swal.fire({
// 			title: 'Berhasil!',
// 			text: 'Berhasil Menghapus Data',
// 			icon: 'success',
// 			showConfirmButton: false,
// 			timer: 1500
// 		})
// 		getDataResult()
// 		console.log(response.data)
// 	})
// 	.catch((error) => {
// 		Swal.fire({
// 			title: 'Gagal!',
// 			text: 'Gagal menghapus data',
// 			icon: 'error',
// 			confirmButtonText: 'Ok'
// 		})
// 		console.log(error)
// 	})

// 	getDataResult()
// }

// Action View and Download
const handleDownload = async (nama_dokumen) => {
    await axios.get(`api/membership/getDokumen/${nama_dokumen}`, {responseType: "blob"})
    .then((response) => {
        console.log(response)
        let blob    = new Blob([response.data], {type: 'application/msword'});
        let url         = window.URL || window.webkitURL;
        let fileUrl     = url.createObjectURL(blob)
        let element     = document.createElement('a')
        element.href    = fileUrl;
        element.setAttribute('download',`${nama_dokumen}`)
        document.body.appendChild(element)
        element.click()
        window.URL.revokeObjectURL(blob)
    })
    .catch(() => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Dokumen Tidak Ditemukan',
			icon: 'error',
			confirmButtonText: 'Ok'
		})
    })
}


onMounted(() => {
    getDataResult()
})
</script>

<style>
	
</style>